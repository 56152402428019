import React from 'react'
import { Link } from 'gatsby'
import FlexContainer from './layout/flex-container'
import { css } from '@emotion/core'

const PageSelector = ({ currentPage, numPages, location }) => {
  const prevPage = currentPage - 1 > 1 ? currentPage - 1 : ''
  const nextPage = currentPage + 1
  const firstPageText = '⇚ First'
  const previousPageText = '↤ Previous'
  const nextPageText = 'Next ↦'
  const lastPageText = 'Last ⇛'

  const baseUrl = _getBaseUrl(location)

  return numPages > 1 ? (
    <FlexContainer
      css={css`
        align-items: center;
        justify-content: space-between;
        margin: auto;
        @media (min-width: 576px) {
          max-width: 60%;
        }
      `}
    >
      {currentPage !== 1 ? (
        <Link to={`${baseUrl}`}>{firstPageText}</Link>
      ) : (
        <span>{firstPageText}</span>
      )}
      {currentPage > 1 ? (
        <Link to={`${baseUrl}/${prevPage}`}>{previousPageText}</Link>
      ) : (
        <span>{previousPageText}</span>
      )}
      <span
        css={css`
          font-style: italic;
        `}
      >
        <strong>{currentPage}</strong> of {numPages}
      </span>
      {currentPage < numPages ? (
        <Link to={`${baseUrl}/${nextPage}`}>{nextPageText}</Link>
      ) : (
        <span>{nextPageText}</span>
      )}
      {numPages !== currentPage ? (
        <Link to={`${baseUrl}/${numPages}`}>{lastPageText}</Link>
      ) : (
        <span>{lastPageText}</span>
      )}
    </FlexContainer>
  ) : null
}

export default PageSelector

function _getBaseUrl(location) {
  const locArray = ['', ...location.pathname.split('/').filter(s => s !== '')]
  const lastElement = locArray[locArray.length - 1]

  if (Number(lastElement) > 0) {
    locArray.splice(locArray.length - 1, 1)
    return locArray.join('/')
  }
  return locArray.join('/')
}
