import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import PostPreview from '../components/post-preview'
import PageSelector from '../components/page-selector'

const BlogList = ({ data, pageContext, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMdx.edges
  const { currentPage, numPages } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={`All posts - page ${currentPage}`}
        description="Federico Gambarino's Blog"
      />
      {posts.map(({ node }) => {
        const { slug } = node
        const title = node.frontmatter.title || slug
        const { date, tags, description } = node.frontmatter
        return (
          <PostPreview
            key={slug}
            slug={`/${slug}`}
            title={title}
            date={date}
            tags={tags}
            description={description || node.excerpt}
          ></PostPreview>
        )
      })}
      <PageSelector
        currentPage={currentPage}
        numPages={numPages}
        location={location}
      />
    </Layout>
  )
}

export default BlogList

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          slug
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`
