import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

const ChipContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  background-color: var(--colors-chips);
  border: none;
  height: 26px;
  outline: none;
  padding: 0;
  font-size: 14px;
  white-space: nowrap;
  align-items: center;
  border-radius: 16px;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;
  margin-right: 2px;
`
const ChipContent = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
`

const Chip = ({ children, clickHandler }) => (
  <ChipContainer
    onClick={clickHandler}
    css={
      (clickHandler &&
        css`
          cursor: pointer;
        `) ||
      null
    }
  >
    <ChipContent>{children}</ChipContent>
  </ChipContainer>
)

export default Chip
