import React from 'react'
import { Link, navigate } from 'gatsby'
import { rhythm } from '../utils/typography'
import Chip from './chip'
import FlexContainer from '../components/layout/flex-container'
import { css } from '@emotion/core'
import * as _ from 'lodash'

const PostPreview = ({ slug, title, date, tags, description }) => (
  <article key={slug}>
    <header>
      <FlexContainer
        css={css`
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div>
          {' '}
          <h3
            style={{
              marginBottom: rhythm(1 / 4),
            }}
          >
            <Link to={slug}>{title}</Link>
          </h3>
          <small>{date}</small>
        </div>
        <div>
          {tags &&
            tags.map(t => (
              <Chip
                css={css`
                  margin-right: 2px;
                `}
                key={t}
                clickHandler={() => navigate(`/blog/tags/${_.kebabCase(t)}`)}
              >
                {t}
              </Chip>
            ))}
        </div>
      </FlexContainer>
    </header>
    <section>
      <p
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    </section>
  </article>
)

export default PostPreview
